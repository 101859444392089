<template>
    <div>
        <div class="table-page-title">
            <!--<div class="fl">
                <el-form :model="searchForm" class="search-form" :inline="true">
                    <el-form-item label="" prop="name">
                        <el-input class="small-input" v-model="searchForm.name" placeholder="单位名称"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="fl search-form-btn">
                <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
                <el-button class="fl" size="small" @click="reset()">重 置</el-button>
            </div>-->
            <div class="ft">
                <el-button size="small" type="primary" @click="add">新建广告组</el-button>
            </div>
        </div>
        <div class="table-container">
            <el-table
                    class="table"
                    height="100%"
                    v-loading="tableLoading"
                    :data="tableData">
                <el-table-column
                        label="广告组"
                        prop="adName"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <!--<el-table-column
                        label="轮播间隔/秒"
                        width="100"
                        prop="rotationInterval"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="等待时长/分钟"
                        width="150"
                        prop="waitingTime"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>-->
                <el-table-column
                        label="生效范围"
                        prop="adScope"
                        align="center"
                        show-overflow-tooltip="true"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="展示状态"
                        prop="status"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                    <template slot-scope="{row}">
                        <span>{{ row.status === '0' ? '展示' : row.status === '1' ? '不展示' : '' }}</span>
                    </template>
                </el-table-column>
                <!--<el-table-column
                        label="创建时间"
                        width="200"
                        prop="createTime"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>-->
                <el-table-column
                        width="100"
                        align="center"
                        header-align="center"
                        label="操作">
                    <template slot-scope="{row}">
                        <el-button type="text" @click="goDetail(row.id)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="top_10 right right_10"
                :current-page="pageNum"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>


<script>
    import tableUse from "@/mixins/tableUse";
    import {setMenuList} from "@/assets/js/common";

    export default {
        name: "AdList",
        mixins: [tableUse],
        data() {
            return {
                searchForm: {
                    name: ''
                },
                tableData: [
                ]
            }
        },
        methods: {
            reset() {
                this.searchForm = {
                    name: ''
                }
                this.search();
            },
            add() {
                setMenuList({
                    path: '/adlist/addad',
                    name: '新增广告'
                })
                this.$router.push({name: 'AddAd'})
            },
            goDetail(id) {
                setMenuList({
                    path: '/adlist/editad',
                    name: '编辑'
                })
                this.$router.push({name: 'EditAd',query:{id:id}})
            },
            getTableData(){
                this.tableLoading = true;
                let formData = {
                    pageNum:this.pageNum,
                    pageSize: this.pageSize
                }
                if(this.searchForm.name) formData.name = this.searchForm.name;
                this.$axios.post("/sys-api/ad/selectAdList",formData,(res) => {
                    this.tableLoading = false;
                    if(res.code === '100'){
                        this.total = res.data.total;
                        this.tableData = res.data.list;
                    }
                })
            }
        },
        mounted() {
            this.getTableData();
        }
    }
</script>

<style scoped lang="scss">

</style>